import axios from 'axios'
// 创建一个 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API + '/api', // 所有的请求地址前缀部分
    timeout: 60000, // 请求超时时间毫秒
})

// 添加请求拦截器
service.interceptors.request.use(
    function (config) {
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    function (response) {
        const dataAxios = response.data
        return dataAxios
    },
     err => {
        return Promise.reject(err)
    }
)

export default service

