import {createRouter, createWebHistory} from 'vue-router'

const routers=[
    {   path:'/Home',
        name:'home',
        component:() => import('@/views/index'),
        meta:{
            title: 'About·汤圆',
            keepAlive: false
        }
    },
    {   path:'/Photo',
        name:'photo',
        component:() => import('@/views/Photo/index'),
        meta:{
            title: 'About·汤圆',
            keepAlive: false
        }
    },
    {   path:'/Album',
        name:'album',
        component:() => import('@/views/Album/index'),
        meta:{
            title: 'About·汤圆',
            keepAlive: false
        }
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routers,
});
router.beforeEach((to,from,next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.path === '/') {
        next('/Home')
    }
    next()
})

export default router