<template>
<!--  遮罩层-->
<!--  <div id="preloader" class="preloader"/>-->
  <el-menu
          :default-active="$route.path"
          class="menu z-index-99"
          mode="horizontal"
          router
  >
    <el-menu-item index="/Home">
      <template #title>
          <img style="width: 30px;margin:10px 0" src="@/assets/logo.png" alt="">
<!--        <span>Me</span>-->
      </template>
    </el-menu-item>
    <el-menu-item index="/Photo">Photo</el-menu-item>
    <el-menu-item index="/Album">Album</el-menu-item>
    <el-menu-item index="" ><a href="https://blog.weisiyuan.top/">Blog</a></el-menu-item>
  </el-menu>
  <router-view/>
  <el-footer style="position: relative;z-index: 999;">
    <el-row :gutter="10">
      <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
        <div v-html="data.webConfig.footerLeft"></div>
      </el-col>
      <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
<!--        <div v-html="data.webConfig.footerCenter"></div>-->
        <a href="https://beian.miit.gov.cn/" target="_blank">桂ICP备2022005927号-1</a>
      </el-col>
      <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
        <div v-html="data.webConfig.footerRight"></div>
      </el-col>
    </el-row>
  </el-footer>
</template>

<script>
  import { reactive } from 'vue'
  import { listConfig } from '@/api/webConfig'
export default {
  name: 'App',
  components: {
  },
  setup(){
    let params = reactive({
      belongTo: 4,
      enable: 1
    })
    let data = reactive({
      webConfig: {
        footerLeft: null,
        footerCenter: null,
        footerRight: null,
      }
    })

    initData()

    function initData() {
      listConfig(params).then(result => {
        if (result !== ''){
          data.webConfig = result
        }
      }).catch(err => {
        console.log(err)
      })
    }
    function BlogClick(){
      window.location.href = 'http://blog.weisiyuan.top/'
    }
    return {BlogClick,data}
  }
}
</script>
