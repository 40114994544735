import request from '@/request'

// 查询网页内容设置列表
export function listConfig(query) {
  return request({
    url: 'webConfig/list',
    method: 'get',
    params: query
  })
}
