import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/dark/css-vars.css'
import '@/assets/css/style.css'
import '@/assets/css/response.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import router from './router'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
// import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import VueLazyload from 'vue-lazyload'
/* add icons to the library */
library.add(fab)

/* add font awesome icon component */
const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(VueAxios, axios)
app.use(VueLazyload)
app.use(router)
app.use(ElementPlus,{
    locale: zhCn,
})
app.mount('#app')
